import React from "react";
import Header from "../layout/Header";
import Enquiryfollowupnav from "../Enquiryfollowupnav";

function Esend() {
  return (
    <div>
      <Header />
      <Enquiryfollowupnav />
      <div className="row m-auto">
        <div className="col-md-12">
          <p>Esend page</p>
        </div>
      </div>
    </div>
  );
}

export default Esend;
