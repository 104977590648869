import React, { useState, useEffect } from "react";
import Header from "../components/layout/Header";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import DSRnav from "./DSRnav";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

function Dsrcallist() {
  const apiURL = process.env.REACT_APP_API_URL;
  const admin = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();
  const { date, category } = useParams();
  const currentdate = new Date();
  const formattedDate = moment(currentdate).format("YYYY-MM-DD");

  function name() {
    return formattedDate === date;
  }
  const yokesh = name();

  const [searchResults, setSearchResults] = useState([]);
  const [dsrdata1, setdsrdata1] = useState([]);
  const [city, setcity] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setloader] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setSelectedData(data);
    setShow(true);
  };

  const editservicecity = async () => {
    try {
      const config = {
        url: `/changeappotimewithoutdsr/${selectedData._id}`,
        method: "post",
        baseURL: apiURL,
        headers: { "content-type": "application/json" },
        data: {
          city: city,
        },
      };
      await axios(config).then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.error(error);
      alert("Something went wrong");
    }
  };

  useEffect(() => {
    getservicedata();
  }, []);

  const getservicedata = async () => {
    setloader(true);
    try {
      let res = await axios.get(apiURL + "/MissedDsrData");

      if (res.status === 200) {
        const allData = res.data?.servicedetails;
        setSearchResults(allData);

        setloader(false);
      }
    } catch (error) {
      setloader(false);
      console.log("Error in Search", error);
    }
  };

  return (
    <div className="web">
      <Header />

      <h2 style={{ color: "darkred" }}> Missed DSR data</h2>
      {/* Add other content here */}
      <div className="row m-auto">
        <div className="col-md-12">
          <table style={{ border: "1px solid black" }}>
            <thead style={{ border: "1px solid black" }}>
              <tr className="table-secondary">
                <th className="table-head-custom">Sr.No</th>
                <th className="table-head-custom">BookDate | Time</th>
                <th className="table-head-custom">ServiceDate </th>
                <th className="table-head-custom">Customer Name</th>
                <th className="table-head-custom">City</th>
                <th className="table-head-custom">Address</th>
                <th className="table-head-custom">Contact No.</th>

                <th className="table-head-custom">Job Type</th>
                <th className="table-head-custom">Job Amount</th>
                <th className="table-head-custom">Payment Mode</th>
                <th className="table-head-custom">Description</th>
                <th className="table-head-custom">Reference</th>
                <th className="table-head-custom">Backendofficer</th>
                <th className="table-head-custom">Action</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "30px",
                  }}
                >
                  <Spinner animation="border" role="status" color="darkred" />
                </div>
              ) : (
                searchResults?.map((selectedData, index) => (
                  <tr
                    style={{
                      border: "1px solid black",
                    }}
                    key={index}
                  >
                    <td className="table-head-custom">{index + 1}</td>
                    <td
                      className="table-head-custom"
                      style={{ width: "190px" }}
                    >
                      {moment(selectedData?.createdAt).format("DD-MM-YYYY")}|{" "}
                      {moment(selectedData?.createdAt).format("LT")}
                    </td>
                    <td className="table-head-custom">
                      {selectedData?.dividedDates[0]?.date}
                    </td>
                    <td className="table-head-custom">
                      {selectedData?.customerData[0]?.customerName}
                    </td>
                    <td className="table-head-custom">{selectedData?.city}</td>
                    <td className="table-head-custom">
                      {selectedData?.deliveryAddress
                        ? `${selectedData?.deliveryAddress?.platNo}, ${selectedData?.deliveryAddress?.address} - ${selectedData?.deliveryAddress?.landmark}`
                        : ""}
                    </td>
                    <td className="table-head-custom">
                      {selectedData?.customerData[0]?.mainContact}
                    </td>
                    <td className="table-head-custom">
                      {selectedData?.service}
                    </td>
                    <td className="table-head-custom">
                      {selectedData?.GrandTotal}
                    </td>
                    <td className="table-head-custom">
                      {selectedData.paymentMode}
                    </td>
                    <td className="table-head-custom">{selectedData?.desc}</td>
                    <td className="table-head-custom">{selectedData?.type}</td>
                    <td className="table-head-custom">
                      {selectedData?.BackofficeExecutive}
                    </td>
                    <td className="table-head-custom">
                      <Button onClick={() => handleShow(selectedData)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>City Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pt-3">
            <select
              className="vhs-table-input"
              value={city}
              onChange={(e) => setcity(e.target.value)}
            >
              <option value="">Select</option>
              {admin?.city
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((city, index) => (
                  <option key={index} value={city.name}>
                    {city.name}
                  </option>
                ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={editservicecity}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Dsrcallist;
