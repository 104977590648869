import "./App.css";

import Navigations from "./components/Navigations";

import React from "react";

function App() {
  return (
    <div>
      <Navigations />
    </div>
  );
}

export default App;
