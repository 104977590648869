import React, { useState, useEffect } from "react";
import Header from "../components/layout/Header";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import DSRnav from "./DSRnav";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

function Dsrcallist() {
  const apiURL = process.env.REACT_APP_API_URL;
  const admin = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();
  const { date, category } = useParams();
  const currentdate = new Date();
  const formattedDate = moment(currentdate).format("YYYY-MM-DD");

  function name() {
    return formattedDate === date;
  }
  const yokesh = name();

  const [searchResults, setSearchResults] = useState([]);
  const [dsrdata1, setdsrdata1] = useState([]);
  const [searchCustomerName, setSearchCustomerName] = useState("");
  const [city, setcity] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [searchTechName, setSearchTechName] = useState("");
  const [searchJobType, setSearchJobType] = useState("");
  const [searchDesc, setSearchDesc] = useState("");
  const [searchpaymentMode, setsearchpaymentMode] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setloader] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [searchReference, setReference] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [searchAddress, setSearchAddress] = useState("");
  const [techNameOptions, setTechNameOptions] = useState([]);

  useEffect(() => {
    // Fetch tech names for dropdown on initial load
    fetchTechNameOptions();
  }, []);

  const fetchTechNameOptions = async () => {
    try {
      const response = await axios.get(`${apiURL}/getFilteredTechNames`, {
        params: {
          category,
          date,
          city: admin.city,
        },
      });
      if (response.status === 200) {
        const techNames = response.data.techNameData
          .map((item) => item.techName) // Extract techName
          .filter((name, index, self) => {
            // Filter out empty names and remove duplicates
            return (
              name &&
              self.indexOf(name) === index &&
              !/^[a-f0-9]{24}$/i.test(name) // Exclude 24-character hexadecimal strings
            );
          })
          .sort((a, b) => a.localeCompare(b)); // Sort alphabetically

        setTechNameOptions(techNames);
      }
    } catch (error) {
      console.error("Error fetching tech names:", error);
    }
  };

  // Function to reset to the first page and fetch filtered data
  const resetPageAndSearchData = () => {
    if (totalCount <= 25) {
      setCurrentPage(1);
      setTotalPages(1);
    }
    getservicedata(1); // Fetch data for the first page
  };

  useEffect(() => {
    // Debounced effect to trigger search on filter input changes
    const debounceTimer = setTimeout(() => {
      resetPageAndSearchData();
    }, 500); // 300ms debounce delay

    return () => clearTimeout(debounceTimer);
  }, [
    category,
    date,
    searchCustomerName,
    searchContact,
    searchTechName,
    city,
    searchJobType,
    searchDesc,
    searchReference,
    searchpaymentMode,
    searchAddress,
  ]);

  const getservicedata = async (page = currentPage) => {
    setloader(true);
    setSearchResults([]); // Clear previous data to avoid showing stale results

    try {
      const res = await axios.get(apiURL + "/getservicedatawithaddcalnew", {
        params: {
          category,
          date,
          city: admin.city,
          filtercity: city,
          customerName: searchCustomerName,
          number: searchContact,
          techname: searchTechName,
          searchJobType,
          searchDesc,
          searchReference,
          searchpaymentMode,
          searchAddress,
          page,
          limit: 25,
        },
      });

      if (res.status === 200) {
        const allData = res.data?.runningdata;

        setloader(false);
        setTotalCount(res.data?.filteredCount);
        setSearchResults(allData);
        setTotalPages(Math.ceil(res.data?.filteredCount / 25));
      }
    } catch (error) {
      setloader(false);
      console.log("Error in Search", error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      getservicedata(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      getservicedata(currentPage - 1);
    }
  };

  const SERVICEdelay = (selectedData) => {
    if (!yokesh) return;
    const givenDate = moment(date, "YYYY-MM-DD");
    const currentDate = moment();
    const isPast = givenDate.isBefore(currentDate, "day");
    if (selectedData?.dsrdata[0]?.startJobTime) {
      return;
    } else {
      const selectedSlotText = selectedData?.selectedSlotText;
      if (!selectedSlotText) {
        console.log("Time range not found for the selected service.");
        return false;
      }
      const currentMoment = moment();
      const [startTime] = selectedSlotText?.split(" - ");
      const slotStartTime = moment(startTime, "hA");
      return currentMoment.isAfter(slotStartTime);
    }
  };

  const SERVICEMode = (service) => {
    const filterpaymentmde = dsrdata1.filter(
      (item) => item.serviceInfo[0]?._id === service._id
    );
    return filterpaymentmde[0]?.paymentType;
  };

  const returndata = (data) => {
    const dateToMatch = new Date(date);
    let charge = 0;
    data.dividedamtDates.forEach((dateObj, index) => {
      const dividedDate = new Date(dateObj.date);
      if (dividedDate.getDate() === dateToMatch.getDate()) {
        charge = data?.dividedamtCharges[index]?.charge;
      }
    });
    return charge || 0;
  };

  const handleRowClick = (selectedData) => {
    const queryData = encodeURIComponent(JSON.stringify(selectedData));
    const queryDate = encodeURIComponent(date);
    const url = `/dsrdetails?data=${queryData}&date=${queryDate}`;

    window.open(url, "_blank", "noopener,noreferrer"); // Open in a new tab with the query parameters
  };

  const getRowBackgroundColor = (selectedData) => {
    if (!selectedData?.dsrdata || selectedData.dsrdata.length === 0) {
      return "white"; // Set a default color for debugging
    }

    // Check if any entry in dsrdata has jobComplete as "CANCEL"
    const isCancelled = selectedData.dsrdata.some(
      (item) => item.jobComplete === "CANCEL" && item.serviceDate === date
    );
    const hasEndJobTime = selectedData.dsrdata.some(
      (item) => item.endJobTime && item.serviceDate === date
    );
    const hasStartJobTime = selectedData.dsrdata.some(
      (item) => item.startJobTime && item.serviceDate === date
    );
    const assignedToTechnician = selectedData.dsrdata.some(
      (item) => item.TechorPMorVendorName && item.serviceDate === date
    );

    if (isCancelled) {
      return "#f44336a8"; // Color for canceled jobs
    } else if (
      selectedData.dsrdata.some((item) => item.jobComplete === "YES")
    ) {
      return "rgb(182, 96, 255)"; // Color for completed jobs
    } else if (hasEndJobTime) {
      return "#4caf50"; // Color for jobs with an end time
    } else if (SERVICEdelay(selectedData)) {
      return "#2196f3"; // Color for delayed services
    } else if (hasStartJobTime) {
      return "#ffff0075"; // Color for jobs that have started
    } else if (assignedToTechnician) {
      return "#e2e3e5"; // Color for jobs assigned to a technician
    }

    return "white"; // Default color for debugging
  };

  const startSerialNumber = (currentPage - 1) * 25 + 1;

  useEffect(() => {
    getAlldata1();
  }, []);
  const [manualcrmdata, setmanualcrmdata] = useState([]);
  const getAlldata1 = async () => {
    try {
      const res = await axios.get(apiURL + `/getmanuljobfromcrmdata`);

      if (res.status === 200) {
        setmanualcrmdata(res.data.data);
      }
    } catch (error) {
      // Handle error
    }
  };
  let TTnameValue = null;
  let vCancel = null;

  const passfunction1 = (sId) => {
    const filt = manualcrmdata?.filter((i) => i.serviceId == sId?._id);

    TTnameValue = filt[0];
    vCancel = filt[0]?.vCancel;

    return TTnameValue;
  };

  const getTechorPMorVendorName = (dsrdata) => {
    // Use find to locate the first object with a non-empty TechorPMorVendorName
    const vendor = dsrdata.find(
      (item) => item.TechorPMorVendorName !== "" && item.serviceDate === date
    );
    return vendor ? vendor.TechorPMorVendorName : ""; // Default to "Not Assigned" if none found
  };

  return (
    <div className="web">
      <Header />
      <DSRnav />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div className="shadow-sm" style={{ border: "1px #cccccc solid" }}>
          <div
            className="ps-1 pe-1"
            style={{ borderBottom: "1px #cccccc solid", cursor: "pointer" }}
          >
            NOT ASSIGNED
          </div>
          <div
            className="ps-1 pe-1"
            style={{
              backgroundColor: "#e2e3e5",
              cursor: "pointer",
            }}
          >
            ASSIGNED FOR TECHNICIAN
          </div>
          <div
            className="ps-1 pe-1"
            style={{
              backgroundColor: "#ffff0075",
              cursor: "pointer",
            }}
          >
            SERVICE STARTED
          </div>
          <div
            className="ps-1 pe-1"
            style={{ backgroundColor: "#4caf50", cursor: "pointer" }}
          >
            SERVICE COMPLETED
          </div>
          <div
            className="ps-1 pe-1"
            style={{ backgroundColor: "#f44336a8", cursor: "pointer" }}
          >
            SERVICE CANCELLED
          </div>
          <div
            className="ps-1 pe-1"
            style={{ backgroundColor: "#2196f3", cursor: "pointer" }}
          >
            SERVICE DELAYED
          </div>
          <div
            className="ps-1 pe-1"
            style={{ backgroundColor: "rgb(182, 96, 255)", cursor: "pointer" }}
          >
            CLOSED OPERATION MANAGER
          </div>
        </div>
      </div>

      <div className="row m-auto">
        <div className="pagination-controls">
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
        <div className="col-md-12">
          <table
            // className="table table-hover table-bordered mt-1"
            style={{ border: "1px solid black" }}
          >
            <thead className="" style={{ border: "1px solid black" }}>
              <tr
                className="table-secondary"
                style={{ border: "1px solid black" }}
              >
                <th className="table-head-custom" scope="col"></th>
                <th
                  className="table-head-custom"
                  style={{ width: "7%" }}
                  scope="col"
                ></th>
                <th scope="col" className="table-head-custom"></th>
                <th scope="col" className="table-head-custom">
                  <input
                    className="vhs-table-input"
                    value={searchCustomerName}
                    onChange={(e) => setSearchCustomerName(e.target.value)}
                  />
                </th>
                <th scope="col" className="table-head-custom">
                  <select
                    className="vhs-table-input"
                    value={city}
                    onChange={(e) => setcity(e.target.value)}
                  >
                    <option value="">Select</option>
                    {admin?.city
                      .slice() // Create a copy of the array to avoid mutating the original
                      .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by 'name'
                      .map((city, index) => (
                        <option key={index} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                  </select>
                </th>
                <th
                  scope="col"
                  style={{ width: "15%" }}
                  className="table-head-custom"
                >
                  <input
                    className="vhs-table-input"
                    value={searchAddress}
                    onChange={(e) => setSearchAddress(e.target.value)}
                  />
                </th>
                <th scope="col" className="table-head-custom">
                  <input
                    className="vhs-table-input"
                    value={searchContact}
                    onChange={(e) => setSearchContact(e.target.value)}
                  />
                </th>
                <th scope="col" className="table-head-custom">
                  <select
                    className="vhs-table-input"
                    value={searchTechName}
                    onChange={(e) => setSearchTechName(e.target.value)}
                  >
                    <option value="">Select Technician</option>
                    {techNameOptions.map((techName, index) => (
                      <option key={index} value={techName}>
                        {techName}
                      </option>
                    ))}
                  </select>
                </th>
                <th scope="col" className="table-head-custom">
                  <input
                    className="vhs-table-input"
                    value={searchJobType}
                    onChange={(e) => setSearchJobType(e.target.value)}
                  />
                </th>
                <th scope="col" className="table-head-custom"></th>
                <th scope="col" className="table-head-custom">
                  {/* <select
                    className="vhs-table-input"
                    value={searchpaymentMode}
                    onChange={(e) => setsearchpaymentMode(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="cash">Cash</option>
                    <option value="online">Online</option>
                  </select> */}
                </th>
                <th scope="col" className="table-head-custom">
                  <input
                    className="vhs-table-input"
                    value={searchDesc}
                    onChange={(e) => setSearchDesc(e.target.value)}
                  />
                </th>
                <th scope="col" className="table-head-custom">
                  <input
                    className="vhs-table-input"
                    value={searchReference}
                    onChange={(e) => setReference(e.target.value)}
                  />
                </th>
              </tr>
              <tr className="table-secondary">
                <th className="table-head-custom" scope="col">
                  Sr.No
                </th>
                <th className="table-head-custom" scope="col">
                  Date
                </th>
                <th className="table-head-custom" scope="col">
                  Time
                </th>
                <th scope="col" className="table-head-custom">
                  Customer Name
                </th>
                <th scope="col" className="table-head-custom">
                  City
                </th>
                <th
                  scope="col"
                  style={{ width: "15%" }}
                  className="table-head-custom"
                >
                  Address
                </th>
                <th scope="col" className="table-head-custom">
                  Contact No.
                </th>
                <th scope="col" className="table-head-custom">
                  Technician
                </th>
                <th scope="col" className="table-head-custom">
                  Job Type
                </th>
                <th scope="col" className="table-head-custom">
                  Job Amount
                </th>
                <th scope="col" className="table-head-custom">
                  Payment mode
                </th>
                <th scope="col" className="table-head-custom">
                  Description
                </th>
                <th scope="col" className="table-head-custom">
                  Reference
                </th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "30px",
                  }}
                >
                  <Spinner animation="border" role="status" color="darkred" />
                </div>
              ) : (
                <>
                  {searchResults?.map((selectedData, index) => (
                    <tr
                      // className="table-head-custom"
                      // className={getRowBackgroundColor(selectedData)}
                      style={{
                        backgroundColor: `${getRowBackgroundColor(
                          selectedData
                        )}`,
                        border: "1px solid black",
                      }}
                      key={index}
                      onClick={() => handleRowClick(selectedData)}
                    >
                      <td className="table-head-custom-body">
                        {startSerialNumber + index}
                      </td>
                      <td className="table-head-custom-body">{date}</td>
                      <td className="table-head-custom-body">
                        {selectedData?.selectedSlotText}
                      </td>
                      <td className="table-head-custom-body">
                        {selectedData?.customerdata[0]?.customerName}
                      </td>
                      <td className="table-head-custom-body">
                        {selectedData?.city}
                      </td>
                      <td className="table-head-custom-body">
                        {selectedData?.deliveryAddress
                          ? `${selectedData?.deliveryAddress?.platNo},
         ${selectedData?.deliveryAddress?.address} - 
         ${selectedData?.deliveryAddress?.landmark}`
                          : ""}
                      </td>
                      <td className="table-head-custom-body">
                        {selectedData?.customerdata[0]?.mainContact}
                      </td>
                      <td className="table-head-custom-body">
                        {selectedData?.dsrdata &&
                        selectedData.dsrdata.length > 0 ? (
                          <p>
                            {" "}
                            {getTechorPMorVendorName(selectedData.dsrdata)}
                          </p>
                        ) : passfunction1(selectedData)
                            ?.TechorPMorVendorName ? (
                          <p
                            style={{
                              background:
                                passfunction1(selectedData)?.vCancel ===
                                "cancel"
                                  ? "red"
                                  : "orange",
                            }}
                          >
                            M ={" "}
                            {passfunction1(selectedData)?.TechorPMorVendorName}
                          </p>
                        ) : null}

                        {selectedData?.dsrdata[0]?.Tcanceldate && (
                          <>
                            <p
                              style={{
                                textDecoration: "underline",
                                marginBottom: 0,
                              }}
                            >
                              Cancel details
                            </p>
                            <p style={{ color: "red" }}>
                              {selectedData?.dsrdata[0]?.Tcanceldate}
                              <br />
                              {selectedData?.dsrdata[0]?.Tcancelreason}
                            </p>
                          </>
                        )}
                        {selectedData?.dsrdata[0]?.rescheduledate && (
                          <>
                            <p
                              style={{
                                textDecoration: "underline",
                                marginBottom: 0,
                              }}
                            >
                              Reschedule details
                            </p>
                            <p style={{ color: "orange" }}>
                              {selectedData?.dsrdata[0]?.rescheduledate}
                              <br />
                              {selectedData?.dsrdata[0]?.reschedulereason}
                              <br />
                              {selectedData?.dsrdata[0]?.rescheduletime}
                            </p>
                          </>
                        )}
                      </td>
                      <td className="table-head-custom-body">
                        {selectedData?.service}
                      </td>
                      <td className="table-head-custom-body">
                        {selectedData?.serviceFrequency === "1"
                          ? selectedData.GrandTotal
                          : returndata(selectedData)
                          ? returndata(selectedData)
                          : "0"}
                        {/* {selectedData?.contractType === "AMC"
                          ? returndata(selectedData)
                            ? returndata(selectedData) 
                            : "0"
                          : selectedData.GrandTotal} */}
                      </td>
                      {selectedData.paymentMode ? (
                        <td className="table-head-custom-body">
                          {selectedData.paymentMode}
                        </td>
                      ) : (
                        <td className="table-head-custom-body">
                          {SERVICEMode(selectedData)}
                        </td>
                      )}
                      <td className="table-head-custom-body">
                        {selectedData?.desc}
                      </td>
                      <td className="table-head-custom-body">
                        {selectedData?.reference
                          ? selectedData?.reference
                          : selectedData?.type}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Dsrcallist;
